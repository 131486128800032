<template>
<div class="search">
  <a-input-search
    :placeholder="placeholder"
    style="width: 300px"
    v-model="searchKey"
    :allowClear="true"
  />
</div>
</template>
<script>
export default {
  props: {
    placeholder: { default: '' }
  },
  data () {
    return {
      searchTimerId: 0,
      searchKey: '',
      searchEle: null
    }
  },
  watch: {
    searchKey (val, oldVal) {
      if (!val) {
        this.searchEle.style.display = ''
      } else {
        this.searchEle.style.display = 'none'
      }
      if (this.searchTimerId) return
      this.searchTimerId = setTimeout(() => {
        this.$emit('searchKeyChange', this.searchKey)
        clearTimeout(this.searchTimerId)
        this.searchTimerId = 0
      }, 300)
    }
  },
  mounted () {
    const elements = document.getElementsByClassName('ant-input-search-icon')
    this.searchEle = elements[0] ? elements[0] : null
  },
  beforeDestroy () {
    clearTimeout(this.searchTimerId)
    this.searchTimerId = 0
  }
}
</script>
