<template>
 <a-modal
      :visible="isShow"
      class="customermodel1"
      @ok="onOk"
      @cancel="onCancel"
      width="400px"
      :centered="true"
      :footer="false"
      :closable="false"
      :maskClosable="true"
    >
      <div class="con delclasses-model">
        <div class="icte">
          <div class="icon"><img src="~@/assets/images/icon-del.png" /></div>
          <div class="text">
            <h1>删除"{{message}}"</h1>
          </div>
        </div>
        <div class="button">
          <a-button
            size="large"
            @click="onCancel"
          >取消</a-button>
          <a-button
            size="large"
            type="primary"
            @click="onOk"
          >确认</a-button>
        </div>
      </div>
</a-modal>
</template>
<script>
export default {
  inject: ['eventBus'],
  data () {
    return {
      message: '',
      emitData: undefined,
      isShow: false
    }
  },
  methods: {
    ShowModal (message, data) {
      this.message = message
      this.emitData = data
      this.isShow = true
    },
    onOk () {
      this.$emit('confirmDelete', this.emitData)
      this.isShow = false
    },
    onCancel () {
      this.isShow = false
    }
  },
  created () {
    this.eventBus.$on('show-deleteconfirm-modal', this.ShowModal)
  },
  beforeDestroy () {
    this.eventBus.$off('show-deleteconfirm-modal', this.ShowModal)
  }
}
</script>
